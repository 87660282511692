import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-nz';

import { Modal } from '../_components';

import { userActions } from '../_actions';

class Customer extends React.Component {

    constructor(props) {
        super(props);

        this.handleEditCustomer = this.handleEditCustomer.bind(this);
        this.handleDeleteCustomer = this.handleDeleteCustomer.bind(this);
        this.handleDeleteCustomerConfirmation = this.handleDeleteCustomerConfirmation.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.state = {
            modal: false
        };
    }

    handleEditCustomer() {
        this.props.dispatch(userActions.selectCustomer(this.props.customer));
    }

    handleDeleteCustomer() {
        this.setState({ modal: true })
    }

    handleDeleteCustomerConfirmation() {
        this.props.dispatch(userActions.deleteCustomer(this.props.customer));
        this.hideModal();
    }

    hideModal() {
        this.setState({ modal: false })
    }

    render() {
        const { customer, deletingCustomer } = this.props;
        const { modal } = this.state;
        if (customer) {
            return(
                <div className="col-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100 bg-dark">
                        <div className="card-header">
                            {customer.surname + ", " + customer.firstName}
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div>Id - <strong>{customer.id}</strong></div>
                                <div>Email - <a href={'mailto:' + customer.email}>{customer.email}</a></div>
                                <div>Phone - <strong>{customer.phoneNumbers}</strong></div>
                                <div>Spent so far - <strong>${customer.totalSpent.toLocaleString(undefined)}</strong></div>
                                <div>Last visit - <strong className={customer.lastPaidVisit ? '' : 'text-danger'}>{customer.lastPaidVisit ? moment(customer.lastPaidVisit, "YYYY-MM-DD").format('Do MMMM YYYY') : 'never'}</strong></div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-primary btn-sm mr-1" onClick={this.handleEditCustomer}>Details</button>
                            { (!customer.slotGroupsView || !customer.slotGroupsView.slotGroupViews || customer.slotGroupsView.slotGroupViews.length === 0) &&
                                <button type="button" className="btn btn-danger btn-sm mr-1" onClick={this.handleDeleteCustomer}>Delete</button>
                            }
                        </div>
                    </div>
                    {modal &&
                        <Modal id="deleteConfirmation"
                            title="Delete confirmation"
                            body= {`Are you sure you want to delete the customer?`}
                            primaryButtonLabel="Yes"
                            primaryButtonLabelWait="Deleting..."
                            primaryFunction={this.handleDeleteCustomerConfirmation}
                            primaryFunctionWait={deletingCustomer}
                            primaryButtonClass="btn-danger"
                            cancelFunction={this.hideModal}/>
                    }
                </div>
            );
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedCustomer = connect(mapStateToProps)(Customer);
export { connectedCustomer as Customer };
