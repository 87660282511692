import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../_actions';
import { appConstants } from '../../_constants';
import { SubmitButton } from '../../_components';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());
        // reset the other statuses as well but keep the 3000ms as we still want to see potential popup messages so we can read them?
        this.sleep(3000).then(() => {
            this.props.dispatch(
                { type: appConstants.RESET_REDUX }
            );
        });

        this.state = {
            username: '',
            password: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    sleep(millis) {
      return new Promise(resolve => setTimeout(resolve, millis));
    }

    handleSubmit(e) {
        e.preventDefault();
        var form = document.getElementById('loginForm');
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        form.classList.add('was-validated')

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { loggingIn, showSpamMessage } = this.props;
        const { username, password } = this.state;
        return (
            <div className="col-12 col-sm-10 col-sm-offset-1 col-md-5 col-md-offset-6 col-lg-4 col-lg-offset-4">
                <img src="../logo.svg"  className="img-fluid-half" alt=""/>
                {showSpamMessage &&
                    <div className="alert alert-info" role="alert">
                        Temporary password will be emailed shortly (don't forget to check your <strong className="pink">spam/junk folder</strong>). Please do change your password in your profile once you login!
                    </div>
                }
                <div className="card card-primary card-transparent">
                    <div className="card-body">
                        <h2 className="text-center">Please login</h2>
                        <form id="loginForm" noValidate className="needs-validation my-4" onSubmit={this.handleSubmit} >
                            <div className="form-group">
                                <label className="sr-only" htmlFor="username">Email</label>
                                <input type="email" className="form-control" required name="username" placeholder="Email" value={username} onChange={this.handleChange} />
                                <div className="invalid-feedback">
                                    Please enter your email
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="password">Password</label>
                                <input type="password" className="form-control" required name="password" placeholder="Password" value={password} onChange={this.handleChange} />
                                <div className="invalid-feedback">
                                    Please enter your password
                                </div>
                            </div>
                            <div className="form-group m-1">
                                <SubmitButton updating={loggingIn} label="Login" labelLoading="Login in ..." className="btn-block"/>
                            </div>
                            <div className="form-group text-center m-1">
                                <Link to="/login/resetPassword" className="m-2">Password reset</Link>
                                <Link to="/register" className="m-2">Create account</Link>
                            </div>
                            <div className="form-group text-center m-1">
                                <span className="align-middle">Back to </span>
                                <a className="p-0" href="https://ww2.euromassage.co.nz">Home</a>
                                <span className="align-middle"> page</span>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn, showSpamMessage } = state.user;
    return {
        loggingIn,showSpamMessage
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
