import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { treatmentGroup } from './treatmentGroup.reducer';
import { user } from './user.reducer';
import { slotGroup } from './slotGroup.reducer';
import { reservation } from './reservation.reducer';
import { reservationTherapist } from './reservationTherapist.reducer';
import { place } from './place.reducer';
import { voucher } from './voucher.reducer';
import { finance } from './finance.reducer';
import { statistics } from './statistics.reducer';
import { userConstants, appConstants } from '../_constants';
import { http } from '../_helpers';

const appReducer = combineReducers({
    alert,
    treatmentGroup,
    user,
    slotGroup,
    reservation,
    reservationTherapist,
    place,
    voucher,
    finance,
    statistics
});

const rootReducer = ( state, action ) => {
    if ( action.type === appConstants.APP_RESET_REDUX || action.type === userConstants.LOGIN_FAILURE ) {
        console.warn("Clearing local storage and returning undefined state in appReducer!")
        localStorage.clear();
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export default rootReducer;
