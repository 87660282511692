import { userConstants, reservationTherapistConstants, reservationConstants, slotGroupConstants } from '../_constants';
import { userService, authenticationService } from '../_services';
import { alertActions } from './';
import { history, http } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    updateLoggedInUser,
    updateSelectedCustomer,
    createCustomer,
    deleteCustomer,
    updatePassword,
    securityQuestion,
    resetPassword,
    resetPasswordByAdmin,
    updateSecurityQuestion,
    getCustomers,
    setCustomerSearchString,
    setCustomerSearchPage,
    getEnhancedCustomers,
    resetCustomers,
    getTherapists,
    createLeave,
    findLeave,
    removeLeaveDialogVisibility,
    removeLeave,
    selectCustomer,
    resetCustomer,
    selectTherapist,
    resetTherapist,
    updateTherapist,
    viewSlotGroup
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        authenticationService
            .login(username, password)
            .then(response => {
                userService.getUser()
                .then(
                    user => {
                        dispatch(success(user));
                        dispatch(alertActions.clear());
                        history.push('/');
                    },
                    error => {
                        var message = http.handleServiceError(error);
                        dispatch(failure(message));
                        dispatch(alertActions.error(message));
                    }
                );
            },
            error => {
                var message = http.handleServiceError(error, true);
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(message) { return { type: userConstants.LOGIN_FAILURE, message } }
}

function logout() {
    return dispatch => {
        authenticationService
            .logout()
            .then(response => {
                dispatch(success());
                history.push('/login');
            },
            error => {
                var message = http.handleServiceError(error, true);
                dispatch(alertActions.error(message));
            });
    }

    function success() { return { type: userConstants.LOGOUT } }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.save(user, false)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('Registration successful - please check your inbox and spam folder for your new password'));
                    dispatch(logout());
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(message) { return { type: userConstants.REGISTER_FAILURE, message } }
}

function createCustomer(user) {
    return dispatch => {
        dispatch(request(user));

        userService.save(user, true)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('New user (' + user.surname + ', ' + user.firstName + ') has been created'));
                    history.push('/customers');
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_SUCCESS, user } }
    function failure(message) { return { type: userConstants.CREATE_FAILURE, message } }
}

function deleteCustomer(user) {
    return dispatch => {
        dispatch(request());
        userService.deleteUser(user)
            .then(
                event => {
                    dispatch(success());
                    dispatch(resetCustomers());
                    dispatch(alertActions.success('The customer has been deleted.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_REQUEST } }
    function resetCustomers() { return { type: userConstants.RESETCUSTOMERS_SUCCESS } }
    function success(user) { return { type: userConstants.DELETE_SUCCESS, user } }
    function failure(message) { return { type: userConstants.DELETE_FAILURE, message } }
}

function updateLoggedInUser(user) {
    return dispatch => {
        dispatch(request(user));

         userService.update(user)
             .then(
                 user => {
                     dispatch(success(user));
                     dispatch(alertActions.success('Update successful'));
                 },
                 error => {
                     var message = http.handleServiceError(error);
                     dispatch(failure(message));
                     dispatch(alertActions.error(message));
                 }
             );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(message) { return { type: userConstants.UPDATE_FAILURE, message } }
}

function updateSelectedCustomer(user) {
    return dispatch => {
        dispatch(request(user));

         userService.update(user)
             .then(
                 user => {
                     dispatch(success(user));
                     dispatch(resetCustomers());
                     history.push("/customers");
                     dispatch(alertActions.success('Update successful'));
                 },
                 error => {
                     var message = http.handleServiceError(error);
                     dispatch(failure(message));
                     dispatch(alertActions.error(message));
                 }
             );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(selectedCustomer) { return { type: userConstants.SELECTEDCUSTOMER_SUCCESS, selectedCustomer } }
    function resetCustomers() { return { type: userConstants.RESETCUSTOMERS_SUCCESS } }
    function failure(message) { return { type: userConstants.UPDATE_FAILURE, message } }
}

function updatePassword(password) {
    return dispatch => {
        if (password.new !== password.confirm) {
          var message = 'The re-entered password does not match your new password';
          dispatch(failure(message));
          dispatch(alertActions.error(message));
          return;
        }
        dispatch(request());
         userService.updatePassword(password.old, password.new)
             .then(
                 response => {
                     dispatch(success());
                     dispatch(alertActions.success('Your password has been successfully updated'));
                     history.push("/profile");
                 },
                 error => {
                     var message = http.handleServiceError(error);
                     dispatch(failure(message));
                     dispatch(alertActions.error(message));
                 }
             );
    };

    function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.UPDATE_PASSWORD_SUCCESS } }
    function failure(message) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, message } }
}



function securityQuestion(username, gRecaptchaResponse) {
  return dispatch => {
      dispatch(request());

      userService.securityQuestion(username, gRecaptchaResponse)
          .then(
              question => {
                  dispatch(success(question));
              },
              error => {
                  var message = http.handleServiceError(error);
                  dispatch(failure(message));
                  dispatch(alertActions.error(message));
              }
          );
  };

  function request() { return { type: userConstants.SECURITY_QUESTION_REQUEST } }
  function success(question) { return { type: userConstants.SECURITY_QUESTION_SUCCESS, question } }
  function failure(message) { return { type: userConstants.SECURITY_QUESTION_FAILURE, message } }
}

function updateSecurityQuestion(securityQuestion) {
    return dispatch => {
        dispatch(request());
        userService.updateSecurityQuestion(securityQuestion)
           .then(
               response => {
                   dispatch(success());
                   dispatch(alertActions.success('Your security question has been successfully updated'));
                   history.push("/profile");
               },
               error => {
                   var message = http.handleServiceError(error);
                   dispatch(failure(message));
                   dispatch(alertActions.error(message));
               }
     );
    };

    function request() { return { type: userConstants.UPDATE_SECURITY_QUESTION_REQUEST } }
    function success() { return { type: userConstants.UPDATE_SECURITY_QUESTION_SUCCESS } }
    function failure(message) { return { type: userConstants.UPDATE_SECURITY_QUESTION_FAILURE, message } }
}

function resetPassword(username, securityAnswer) {
  return dispatch => {
      dispatch(request());

      userService.resetPassword(username, securityAnswer)
          .then(
              response => {
                  dispatch(success());
                  dispatch(alertActions.success('Password has been reset. Please check your email and spam folder for new password and change it after you log in.'));
                  history.push('/login');
              },
              error => {
                  var message = http.handleServiceError(error);
                  dispatch(failure(message));
                  dispatch(alertActions.error(message));
              }
          );
  };

  function request() { return { type: userConstants.RESET_PASSWORD_REQUEST } }
  function success() { return { type: userConstants.RESET_PASSWORD_SUCCESS } }
  function failure(message) { return { type: userConstants.RESET_PASSWORD_FAILURE, message } }
}

function resetPasswordByAdmin(user) {
  return dispatch => {
      if (!user.email) {
          dispatch(alertActions.error('This customer does not have an email so we can\'t reset the password.'));
          return;
      } else {
          dispatch(request());
      }

      userService.resetPasswordByAdmin(user.id)
          .then(
              response => {
                  dispatch(success());
                  dispatch(alertActions.success('Password has been reset. Email with new password will be sent shortly.'));
              },
              error => {
                  var message = http.handleServiceError(error);
                  dispatch(failure(message));
                  dispatch(alertActions.error(message));
              }
          );
  };

  function request() { return { type: userConstants.RESET_PASSWORD_BY_ADMIN_REQUEST } }
  function success() { return { type: userConstants.RESET_PASSWORD_BY_ADMIN_SUCCESS } }
  function failure(message) { return { type: userConstants.RESET_PASSWORD_BY_ADMIN_FAILURE, message } }
}

function getCustomers(startsWith) {
    return dispatch => {
        dispatch(request());
        userService.getCustomers(startsWith, false)
            .then(
                customers => {
                    if (!!customers) {
                        dispatch(success(customers));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.GETCUSTOMERS_REQUEST } }
    function success(customers) { return { type: userConstants.GETCUSTOMERS_SUCCESS, customers } }
    function failure(message) { return { type: userConstants.GETCUSTOMERS_FAILURE, message } }
}

function setCustomerSearchString(startsWith) {
    return dispatch => {
        dispatch(searchString(startsWith));
    };
    function searchString(searchString) { return { type: userConstants.GETCUSTOMERS_SEARCHSTRING, searchString } }

}

function setCustomerSearchPage(page) {
    return dispatch => {
        dispatch(searchPage(page));
    };
    function searchPage(searchPage) { return { type: userConstants.GETCUSTOMERS_SEARCHPAGE, searchPage } }

}

function getEnhancedCustomers(startsWith, page) {
    return dispatch => {
        dispatch(resetCustomers())
        dispatch(request());
        userService.getCustomers(startsWith, true, 30, page)
            .then(
                customers => {
                    if (!!customers) {
                        dispatch(success(customers));
                    }
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };
    function resetCustomers() { return { type: userConstants.RESETCUSTOMERS_SUCCESS } }
    function request() { return { type: userConstants.GETCUSTOMERS_REQUEST } }
    function success(customers) { return { type: userConstants.GETCUSTOMERS_SUCCESS, customers } }
    function failure(message) { return { type: userConstants.GETCUSTOMERS_FAILURE, message } }
}

function resetCustomers() {
    return dispatch => {
        dispatch(success());
    };
    function success() {
      return { type: userConstants.RESETCUSTOMERS_SUCCESS }
    }
}

function getTherapists() {
    return dispatch => {
        dispatch(request());
        userService.getTherapists()
            .then(
                therapists => dispatch(success(therapists)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.GETTHERAPISTS_REQUEST } }
    function success(therapists) { return { type: userConstants.GETTHERAPISTS_SUCCESS, therapists } }
    function failure(message) { return { type: userConstants.GETTHERAPISTS_FAILURE, message } }
}

function createLeave(event) {
    return dispatch => {
        dispatch(request());

        userService.createLeave(event)
            .then(
                event => {
                    dispatch(success(event));
                    dispatch(resetSlotGroups());
                    dispatch(resetReservationTherapist());
                    dispatch(resetLeave());
                    history.push('/bookingCalendar');
                    dispatch(alertActions.success('The leave has been created.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.LEAVE_REQUEST } }
    function success(event) { return { type: userConstants.LEAVE_SUCCESS, event } }
    function failure(message) { return { type: userConstants.LEAVE_FAILURE, message } }
    function resetReservationTherapist() { return { type: reservationTherapistConstants.RESETGROUP_SUCCESS, event } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS, event } }
    function resetLeave() { return { type: userConstants.RESETLEAVE_SUCCESS, event } }
}

function findLeave(start, end) {
    return dispatch => {
        dispatch(request());

        userService.findLeave(start, end)
            .then(
                slotGroups => dispatch(success(slotGroups)),
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.FINDLEAVE_REQUEST } }
    function success(slotGroups) { return { type: userConstants.FINDLEAVE_SUCCESS, slotGroups } }
    function failure(message) { return { type: userConstants.FINDLEAVE_FAILURE, message } }
}

function removeLeaveDialogVisibility(holidayId) {
    return dispatch => {
        dispatch(dialog(holidayId));
    };
    function dialog(holidayId) { return { type: userConstants.REMOVELEAVE_DIALOG, holidayId } }
}

function removeLeave(event, destination) {
    return dispatch => {
        dispatch(request());
        var localEvent = event;
        userService.removeLeave(event)
            .then(
                response => {
                    dispatch(success(event));
                    dispatch(resetSlotGroups());
                    dispatch(resetReservationTherapist());
                    dispatch(dialog(null));
                    if (destination) {
                        history.push(destination);
                    }
                    dispatch(alertActions.success('The leave has been removed.'));
                },
                error => {
                    var message = http.handleServiceError(error);
                    dispatch(failure(message));
                    dispatch(alertActions.error(message));
                }
            );
    };

    function request() { return { type: userConstants.REMOVELEAVE_REQUEST } }
    function success(event) { return { type: userConstants.REMOVELEAVE_SUCCESS, event } }
    function failure(message) { return { type: userConstants.REMOVELEAVE_FAILURE, message } }
    function resetReservationTherapist() { return { type: reservationTherapistConstants.RESETGROUP_SUCCESS} }
    function resetTherapist() { return { type: userConstants.RESETTHERAPIST_SUCCESS } }
    function resetSlotGroups() { return { type: slotGroupConstants.RESETGROUPS_SUCCESS } }
    function dialog(holidayId) { return { type: userConstants.REMOVELEAVE_DIALOG, holidayId } }
}

function selectCustomer(selectedCustomer) {
    if (typeof(selectedCustomer) == 'object') {
        return dispatch => {
            dispatch(success(selectedCustomer));
            history.push('/customerEdit');
        };
        function success(selectedCustomer) {
          return { type: userConstants.SELECTEDCUSTOMER_SUCCESS, selectedCustomer }
        }
    } else {
        return dispatch => {
             userService.getCustomer(selectedCustomer)
                 .then(
                     customer => {
                         dispatch(success(customer));
                         history.push('/customerEdit');
                     },
                     error => {
                         var message = http.handleServiceError(error);
                         dispatch(alertActions.error(message));
                     }
                 );
        };

        function success(selectedCustomer) {return { type: userConstants.SELECTEDCUSTOMER_SUCCESS, selectedCustomer }}
    }
}

function resetCustomer() {
    return dispatch => {
        history.goBack();
        dispatch(success());
    };
    function success() {
      return { type: userConstants.RESETCUSTOMER_SUCCESS }
    }
}

function selectTherapist(selectedTherapist) {
    return dispatch => {
        dispatch(success(selectedTherapist));
        history.push('/therapistEdit');
    };
    function success(selectedTherapist) {
      return { type: userConstants.SELECTEDTHERAPIST_SUCCESS, selectedTherapist }
    }
}

function resetTherapist() {
    return dispatch => {
        dispatch(success());
        history.push('/therapists');
    };
    function success() {
      return { type: userConstants.RESETTHERAPIST_SUCCESS }
    }
}

function updateTherapist(therapist) {
    return dispatch => {
        dispatch(request(therapist));

         userService.updateTherapist(therapist)
             .then(
                 therapist => {
                     dispatch(success(therapist));
                     dispatch(resetTherapists());
                     history.push("/therapists");
                     dispatch(alertActions.success('Update successful'));
                 },
                 error => {
                     var message = http.handleServiceError(error);
                     dispatch(failure(message));
                     dispatch(alertActions.error(message));
                 }
             );
    };

    function request(therapist) { return { type: userConstants.UPDATE_REQUEST, therapist } }
    function success(therapist) { return { type: userConstants.SELECTEDTHERAPIST_SUCCESS, therapist } }
    function resetTherapists() { return { type: userConstants.RESETTHERAPISTS_SUCCESS } }
    function failure(message) { return { type: userConstants.UPDATE_FAILURE, message } }
}

function viewSlotGroup(selectedSlotGroup) {
    return dispatch => {
        dispatch(success(selectedSlotGroup));
        dispatch(setBackDestination('/customerEdit'));
        history.push('/slotGroupDetails');
    };
    function success(selectedEvent) {
        return { type: reservationConstants.SELECTEDEVENT_SUCCESS, selectedEvent }
    }
    function setBackDestination(backDestination) {
        return { type: reservationConstants.SELECTEDEVENTBACKDESTINATION_SUCCESS, backDestination }
    }
}
