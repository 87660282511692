import React from 'react';
import { connect } from 'react-redux';
import { http } from '../_helpers';
import moment from 'moment';
import 'moment/locale/en-nz';

import { slotGroupActions, userActions } from '../_actions';
import { SubmitButton } from '../_components';

class BookingTherapistSlotGroupDetailsPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handlePriceOverride = this.handlePriceOverride.bind(this);
        this.handleReasonBackClick = this.handleReasonBackClick.bind(this);
        this.handlePriceOverrideBackClick = this.handlePriceOverrideBackClick.bind(this);
        this.handleSubmitWithReason = this.handleSubmitWithReason.bind(this);
        this.handleSubmitWithPriceOverride = this.handleSubmitWithPriceOverride.bind(this);
        this.handlePayClick = this.handlePayClick.bind(this);
        this.handlePayCashClick = this.handlePayCashClick.bind(this);
        this.handlePayElectronicallyClick = this.handlePayElectronicallyClick.bind(this);
        this.handlePayWaivedClick = this.handlePayWaivedClick.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleDetailCustomer = this.handleDetailCustomer.bind(this);

        this.state = {
            showReject: false,
            showReasonField: false,
            reason: '',
            rejecting: false,
            showPriceOverride: false,
            saving: false
        };
    }

    componentDidMount() {
        if (!this.props.loggedAs) {
            this.props.history.push("/login");
        }
        if (!this.props.reservation.selectedEvent) {
            this.props.history.push("/bookingCalendar");
        }
        if (this.props.reservation && this.props.reservation.selectedEvent)
        this.setState({ sessions: this.props.reservation.selectedEvent.sessions });
    }

    handleBackClick() {
        if (this.props.reservation.selectedEvent) {
            this.props.dispatch(slotGroupActions.resetEvent());
        }
        if (this.props.reservation.selectedEventBackDestination) {
            let backDestination = this.props.reservation.selectedEventBackDestination;
            this.props.dispatch(slotGroupActions.resetEventBackDestination());
            this.props.history.push(backDestination);
        } else {
            this.props.history.push("/bookingCalendar");
        }
    }

    handleConfirmClick() {
        var slotGroup = this.props.reservation.selectedEvent;
        if (slotGroup) {
            slotGroup.stateCode = 'booked';
            this.setState( {saving: true})
            this.props.dispatch(slotGroupActions.confirm(slotGroup));
        }
    }

    handleDeleteClick() {
        var slotGroup = this.props.reservation.selectedEvent;
        if (slotGroup) {
            this.setState( {saving: true})
            this.props.dispatch(slotGroupActions.remove(slotGroup));
        }
    }

    handleRejectClick() {
        this.setState({ showReasonField: true });
        this.setState({ showReject: true });
    }

    handlePayElectronicallyClick() {
        this.handlePayClick("paidElectronically");
    }
    handlePayCashClick() {
        this.handlePayClick("paidByCash");
    }
    handlePayWaivedClick() {
        this.handlePayClick("paidWaived");
    }

    handlePayClick(code) {
        var slotGroup = this.props.reservation.selectedEvent;
        if (slotGroup) {
            this.setState( {saving: true})
            slotGroup.stateCode = code;
            this.props.dispatch(slotGroupActions.pay(slotGroup));
        }
    }

    handlePriceOverride() {
        this.setState({showPriceOverride: true});
    }

    handleReasonBackClick() {
        this.setState({ showReasonField: false });
        this.setState({ showReject: false });
    }

    handlePriceOverrideBackClick() {
        this.setState({ showPriceOverride: false });
    }

    handleSubmitWithReason(e) {
        const {reservation} = this.props;
        e.preventDefault();
        var form = document.getElementById('reasonForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            e.stopPropagation();
            return;
        }
        reservation.selectedEvent.reason = this.state.reason;
        if (this.state.showReject) {
            this.setState({ rejecting: true });
            reservation.selectedEvent.stateCode = 'rejected';
            this.props.dispatch(slotGroupActions.reject(reservation.selectedEvent));
        }
        console.log(reservation.selectedEvent);
    }

    handleSubmitWithPriceOverride(e) {
        if (this.props.slotGroup.priceUpdating) {
            return;
        }
        const {reservation} = this.props;
        e.preventDefault();
        var form = document.getElementById('priceOverrideForm');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            e.stopPropagation();
            return;
        }

        this.setState( {saving: true})
        this.props.dispatch(slotGroupActions.updatePrices(this.state.sessions, reservation.selectedEvent));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handlePriceChange(e) {
        const { value, alt } = e.target;
        const sessions = this.state.sessions.slice();
        if (isNaN(value)) {
            return;
        }
        sessions[alt].price = value;
        this.setState({ sessions: sessions });
    }

    handleDetailCustomer() {
        this.props.dispatch(userActions.selectCustomer(this.props.reservation.selectedEvent.customerId));
    }

    render() {
        const { reservation, slotGroup } = this.props;
        const { rejecting, reason, showReject, showReasonField, sessions, showPriceOverride, saving } = this.state;
        var selectedEvent = reservation.selectedEvent;
        var overlayClass = saving ? "overlay-show" : "overlay-hide";
        if (selectedEvent) {
            return (
                <div className="col-12 col-lg-8">
                    <h2 className="text-center">Selected session</h2>
                    <div className="card card-primary card-transparent">
                        <div className="card-body">
                            <div className={`overlay ${overlayClass}`}>
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <div className="spinner"></div>
                                </div>
                            </div>
                                {selectedEvent.stateCode !== 'notWorking' &&
                                    <div className="form-group row">
                                        <label className="col-4 col-md-3 col-lg-2 col-form-label" htmlFor="customerName">Name</label>
                                        <div className="col-6">
                                            <span className="form-control-plaintext font-weight-bold" name="customerName" onClick={this.handleDetailCustomer}> {selectedEvent.customerName}</span>
                                        </div>
                                        {selectedEvent.customerId != -1 ? <button type="button" className="btn btn-secondary col-2 mb-1" onClick={this.handleDetailCustomer}>Detail</button>:null}
                                        <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                    </div>
                                }
                                {selectedEvent.stateCode !== 'notWorking' &&
                                    <div className="form-group row">
                                        <label className="col-md-3 col-lg-2 col-form-label" htmlFor="customerContact">Contact</label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control-plaintext font-weight-bold" name="customerContact" value={selectedEvent.customerContact} readOnly/>
                                        </div>
                                        <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                    </div>
                                }

                                <div className="form-group row">
                                    <label className="col-md-3 col-lg-2 col-form-label" htmlFor="dateTime">Date&Time</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="dateTime" value={moment(selectedEvent.start).format('dddd, MMMM Do YYYY, h:mm:ss a')} readOnly/>
                                    </div>
                                    <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-lg-2 col-form-label" htmlFor="therapist">Therapist</label>
                                    <div className="col-8">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="therapist" value={selectedEvent.therapist} readOnly/>
                                    </div>
                                    <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-lg-2 col-form-label" htmlFor="stateCode">State</label>
                                    <div className="col-8">
                                        <div className="form-control-plaintext">
                                            <span className="p-1 rounded font-weight-bold" style={{backgroundColor: selectedEvent.backgroundColor}}>
                                                    {selectedEvent.stateCode}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-4 col-md-3 col-lg-2 col-form-label" htmlFor="duration">Duration</label>
                                    <div className="col-8">
                                        <input type="text" className="form-control-plaintext font-weight-bold" name="duration" value={selectedEvent.totalDuration} readOnly/>
                                    </div>
                                    <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                </div>

                                {selectedEvent.stateCode !== 'notWorking' &&
                                    <div className="form-group row">
                                        <label className="col-4 col-md-3 col-lg-2 col-form-label" htmlFor="price">Price</label>
                                        <div className="col-8">
                                            <input type="text" className="form-control-plaintext font-weight-bold" name="price" value={selectedEvent.totalPrice} readOnly/>
                                        </div>
                                        <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                    </div>
                                }

                                { selectedEvent.stateCode !== 'notWorking' && !showPriceOverride &&
                                    <div className="form-group row">
                                        <label className="col-md-3 col-lg-2 col-form-label" htmlFor="treatments">{`Treatment${selectedEvent.sessions.length > 1 ? `s`:``}`}</label>
                                        <ul className="list-unstyled form-group col-md-8">
                                            {selectedEvent.sessions && selectedEvent.sessions.map((item, index) =>
                                                <li key={`session_${index}`} className="form-row">
                                                    <div className="form-control-plaintext font-weight-bold col-12">
                                                        {item.treatmentLabel + " - " + item.priceFormatted + " - " + item.durationFormatted}
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        <hr className="col-10 d-block d-sm-none my-0 mx-3 bg-secondary"/>
                                    </div>
                                 }
                                {showPriceOverride &&
                                    <form noValidate id="priceOverrideForm" className="form-group" onSubmit={this.handleSubmitWithPriceOverride}>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-lg-2 col-form-label" htmlFor="treatments">{`Treatment${selectedEvent.sessions.length > 1 ? `s`:``}`}</label>
                                            <ul className="list-unstyled form-group col-md-8">
                                                {sessions && sessions.map((item, index) =>
                                                    <li key={`priceEdit_${index}`} className="form-row">
                                                        <div className="form-control-plaintext font-weight-bold col-9">
                                                            {item.treatmentLabel + " - " + item.durationFormatted}
                                                        </div>
                                                        <input type="text" className="form-control col-2" name="price" alt={index} value={item.price} onChange={this.handlePriceChange}/>
                                                        <div className="invalid-feedback">
                                                            Please enter valid amount
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="form-group row align-items-center ml-1 ">
                                            <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handlePriceOverrideBackClick}>Back</button>
                                            <SubmitButton updating={slotGroup.priceUpdating} label="Update price" className="btn-danger col-md-4 col-xl-3 mr-2 mb-2" labelLoading="Updating ..."/>
                                        </div>
                                    </form>
                                }

                                {(showReject && selectedEvent.rejectVisible) && showReasonField &&
                                    <form noValidate id="reasonForm" onSubmit={this.handleSubmitWithReason}>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-lg-2 col-form-label" htmlFor="reason">Reason</label>
                                            <div className="col-md-8">
                                                <input type="text" className="form-control font-weight-bold" name="reason" required value={reason} onChange={this.handleChange}/>
                                                <div className="invalid-feedback">
                                                    Please enter the reason
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center ml-1 ">
                                            <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleReasonBackClick}>Back</button>
                                            {showReject && selectedEvent.rejectVisible && <SubmitButton updating={rejecting} label="Reject booking" className="btn-danger col-md-4 col-xl-3 mr-2 mb-2" labelLoading="Rejecting ..."/>}
                                        </div>
                                    </form>

                                }
                                {!showReject && !showReasonField && !showPriceOverride &&
                                    <div className="form-group row align-items-center ml-1 ">
                                        <button type="button" className="btn btn-secondary col-md-2 col-xl-1 mr-2 mb-2" onClick={this.handleBackClick}>Back</button>
                                        {selectedEvent.cancelVisible ? <button type="button" className="btn btn-secondary col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleBackClick}>Cancel</button>:null}
                                        {selectedEvent.confirmVisible ? <button type="button" className="btn btn-primary col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleConfirmClick}>Confirm</button>:null}
                                        {selectedEvent.rejectVisible ? <button type="button" className="btn btn-danger col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleRejectClick}>Reject</button>:null}
                                        {selectedEvent.invoiceVisible ? <a className="btn btn-info col-md-2 col-xl-2 mr-2 mb-2" href={`${http.BASE_URL}/api/slotgroup/${selectedEvent.id}/invoice`} download>Invoice</a>:null}
                                        {selectedEvent.overrideVisible ? <button type="button" className="btn btn-warning col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handlePriceOverride}>Price override</button>:null}
                                        {selectedEvent.paymentsVisible ? <button type="button" className="btn btn-success col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handlePayCashClick}>Cash</button>:null}
                                        {selectedEvent.paymentsVisible ? <button type="button" className="btn btn-success col-md-3 col-xl-2 mr-2 mb-2" onClick={this.handlePayElectronicallyClick}>EFTPOS</button>:null}
                                        {selectedEvent.paymentsVisible ? <button type="button" className="btn btn-success col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handlePayWaivedClick}>No charge</button>:null}
                                        {selectedEvent.deleteVisible ? <button type="button" className="btn btn-danger col-md-2 col-xl-2 mr-2 mb-2" onClick={this.handleDeleteClick}>Delete</button>:null}
                                    </div>
                                }

                        </div>
                    </div>
                </div>

            );
        } else {
            return ("");
        }
    }
}

function mapStateToProps(state) {
    const { loggedAs } = state.user;
    const { reservation, slotGroup } = state;

    return {
        loggedAs,
        reservation,
        slotGroup
    };
}

const connectedBookingTherapistSlotGroupDetailsPage = connect(mapStateToProps)(BookingTherapistSlotGroupDetailsPage);
export { connectedBookingTherapistSlotGroupDetailsPage as BookingTherapistSlotGroupDetailsPage };
